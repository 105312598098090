import "./Maintenance.css"
export default function Maintenance() {
  return (
    <div className="maintenance-body">
      <div className="d-flex flex-column justify-content-center container" style={{height: "100vh"}}>

        <div className="d-flex flex-column justify-content-center text-center align-items-center">
          <img src={process.env.PUBLIC_URL + "/assets/images/resources/langtree-beyaz.png"}/>
          <h1 className="fw-bold text-white m-0 mt-4">We are under maintenance.</h1>

          <h3 className="fw-light text-white mt-2">We apologize for any inconvenience this may cause and appreciate your understanding.</h3>
          <a href="https://x.com/dilagaciprojesi" className="text-decoration-none">
            <h3 className="fw-light text-white mt-2 mb-5">You can follow us at <span className="fw-light" style={{color: "#f3d9cb"}}>x.com/dilagaciprojesi</span> for the latest updates and information.</h3>
          </a>
        </div>
      </div>
    </div>

  );
}

